<template>
  <div class="flex max-w-full flex-col gap-6 p-3">
    <img
      :src="article.image"
      alt=""
      class="prose prose-2xl mx-auto w-full max-w-prose"
    >
    <h1 class="prose prose-2xl mx-auto text-justify font-serif font-bold dark:prose-invert">
      {{ article.title }}
    </h1>
    <div
      id="content"
      class="prose prose-xl mx-auto text-justify font-serif text-black md:prose-2xl dark:prose-invert prose-h1:text-xl prose-img:mx-auto dark:text-white"
    />
    <a
      class="mx-auto rounded-lg p-3 text-sm font-bold dark:text-gray-400"
      :href="article.url"
    >
      go to {{ article?.site?.name }}
    </a>
    <div
      class="container mx-auto w-full rounded-lg border px-10 py-6 text-center text-sm font-bold
      dark:border-0 dark:bg-gray-800 dark:text-gray-400"
      @click="$router.go(-1)"
    >
      back
    </div>
  </div>
</template>

<script>
import articlesApi from '@/api/articles';
import { marked } from 'marked';


export default {
  data() {
    return {
      article: {},
    };
  },
  async mounted() {
    this.article = (await articlesApi.getArticle(this.$route.params.id)).data.data;

    document.getElementById('content').innerHTML =
      marked.parse(this.article.markdown);

    document.title = `feedboard - ${this.article.title}`;
  },
};
</script>
