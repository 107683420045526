<template>
  <main>
    <div class="flex flex-col">
      <div class=" hidden w-96 flex-col dark:bg-gray-950 dark:text-white">
        <div>
          feedboard
        </div>
        <div class="flex flex-col gap-3">
          <div>
            sites
          </div>
          <div
            v-for="site in sites"
            :key="site.id"
          >
            <div class="flex items-center">
              <div>{{ site.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-center">
        <img
          src="@/assets/logo.png"
          class=" max-h-36"
        >
      </div>
      <div class="container mx-auto grid grid-cols-12 gap-3 p-6">
        <div class="col-span-12 flex flex-col gap-6">
          <input
            v-model="search"
            type="text"
            class="w-full rounded-lg border-0 bg-white p-3 text-xs font-bold text-gray-600 shadow
                    dark:border  dark:border-gray-800 dark:bg-transparent dark:text-white"
            placeholder="search"
            @input="searchArticles(search)"
          >
          <button
            v-if="page > 1"
            class="flex justify-between rounded-lg bg-white p-3 text-xs font-bold text-gray-600 shadow-sm
                     dark:bg-gray-800 dark:text-gray-400"
            @click="goToPage(1)"
          >
            <div>
              page {{ page }}
            </div>
            <div>
              return to start
            </div>
          </button>
          <div class="col-span-12 flex flex-row-reverse justify-between gap-6 text-xs font-bold text-gray-600 dark:text-gray-400">
            <p class=" ">
              updated {{ lastArticleDate?.fromNow() }}
            </p>
            <!-- <button
              class="rounded font-bold"
              @click="updateNow()"
            >
              Sync now
            </button> -->
          </div>
        </div>
        <div class="col-span-12 grid grid-cols-12 gap-6">
          <div
            v-for="(article, position) in articles"
            :id="`article-${ position % articlesPerPage }`"
            :key="position"
            class="col-span-12 md:col-span-6 xl:col-span-4"
          >
            <div
              v-if="loading"
              class="flex h-full flex-col rounded-lg bg-white shadow dark:bg-black dark:text-white dark:shadow-none"
            >
              <div class="aspect-video  animate-pulse rounded-t-lg bg-gray-200" />
              <div class="flex h-32 flex-col gap-3 p-6 dark:border-x dark:border-gray-800">
                <div class="h-4 animate-pulse rounded bg-gray-200" />
                <div class="h-4 animate-pulse rounded bg-gray-200" />
                <div class="h-4 animate-pulse rounded bg-gray-200" />
              </div>
              <div class="flex justify-between gap-3 rounded-b-lg px-6 py-2 text-sm font-bold dark:border dark:border-gray-800">
                <div class="h-5 w-20  animate-pulse rounded bg-gray-200" />
                <div class="h-5 w-20  animate-pulse rounded bg-gray-200" />
              </div>
            </div>

            <div
              v-else
              :class="`flex h-full flex-col rounded-lg bg-white shadow hover:shadow-xl hover:shadow-pink-200/50 dark:bg-black dark:text-white dark:shadow-none
                ${scrolledInto == position ? 'dark:opacity-50 opacity-50' : ''}`"
              @click="openArticle(article, position % articlesPerPage)"
            >
              <img
                :src="`${article.image}`"
                class="aspect-video rounded-t-lg object-cover"
              >
              <div class="flex min-h-32 grow flex-col gap-3 p-6 dark:border-x dark:border-gray-800">
                <h2>{{ article.title }}</h2>
              </div>
              <div class="flex justify-between gap-3 rounded-b-lg px-6 py-2 text-sm font-bold dark:border dark:border-gray-800">
                <p>{{ article?.site?.name }}</p>
                <p>{{ article?.author }}</p>

                <p>{{ $dayjs(article?.publishedAt)?.format('DD MMMM YYYY') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <button
            class="w-full rounded-lg  border-0  bg-white p-3 text-xs font-bold shadow
             dark:border  dark:border-gray-800 dark:bg-transparent dark:text-gray-400"
            @click="loadMore()"
          >
            load page {{ page + 1 }}
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import articlesApi from '@/api/articles';
import sitesApi from '@/api/sites';
import statsApi from '@/api/stats';

const ARTICLES_PER_PAGE = 24;

export default {
  name: 'HomeView',
  data() {
    return {
      articles: [...Array(ARTICLES_PER_PAGE).keys()],
      search: null,
      searchTimeout: null,
      lastArticleDate: null,
      page: null,
      sites: [],
      loading: true,
      articlesPerPage: ARTICLES_PER_PAGE,
      scrolledInto: null,
    };
  },
  async mounted() {
    this.page = parseInt(this.$route.query.page) || 1;

    window.title = 'feedboard';

    await this.loadArticles(true);

    this.loading = false;

    if (this.$route.hash) {
      await this.$nextTick();
      const element = document.getElementById(`article-${this.$route.hash.replace('#', '')}`);

      if (element) {
        element.scrollIntoView();
        window.scrollBy(0, -12);

        this.scrolledInto = this.$route.hash.replace('#', '');
      }

      await this.$router.replace({ name: 'home', query: { page: this.page }, hash: '' });
    }

    this.lastArticleDate = this.$dayjs((await statsApi.getLastArticleDate()).data);

    this.sites = (await sitesApi.getAll()).data.data;
  },
  methods: {
    async goToPage(page) {
      this.page = page;

      this.$router.replace({ query: { page: this.page } });

      this.articles = [];

      this.loadArticles();
    },
    async openArticle(article, position) {
      await this.$router.replace({ name:'home', hash: `#${position}`, query: { page: this.page } });
      this.$router.push({ name: 'article', params: { id: article.id } });
    },
    async searchArticles(search) {
      if (search === '') {
        this.search = null;
        this.goToPage(1);
        return;
      }

      this.page = 1;
      this.articles = [];

      this.loadSearchArticles(search);
    },
    async updateNow() {
      sitesApi.syncAll();
    },
    async loadArticles(replace = false) {
      const articles = (
        await articlesApi.getArticles({'page': this.page, 'page-limit': this.articlesPerPage})
      ).data.data;

      if (replace) {
        this.articles = articles;
      } else {
        this.articles.push(...articles);
      }
    },
    async loadSearchArticles(search) {
      this.articles.push(...(await articlesApi.getArticles({'search': search, 'page': this.page, 'page-limit': 250})).data.data);
    },
    async loadMore() {
      this.page += 1;

      this.$router.replace({ query: { page: this.page } });

      if (this.search) {
        this.loadSearchArticles(this.search);
      } else {
        this.loadArticles();
      }
    },
  },
};
</script>
