import api from './index';

export default {
  syncAll() {
    return api.post('/api/sites/sync');
  },
  getAll() {
    return api.get('/api/sites');
  },
  get(id) {
    return api.get(`/api/sites/${id}`);
  }
};
