import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ArticleView from '../views/ArticleView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/LoginView.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { transition: 'none' },
    },
    {
      path: '/sites',
      name: 'sites',
      component: () => import('@/views/sites/Index.vue'),
    },
    {
      path: '/sites/:id',
      name: 'site',
      component: () => import('@/views/sites/Show.vue'),

    },
    {
      path: '/articles/:id',
      name: 'article',
      component: ArticleView,
      meta: { transition: 'none' },
    },
  ],
});

export default router;
