<template>
  <RouterView v-slot="{ Component, route }">
    <transition :name="route.meta.transition || 'none'">
      <KeepAlive :include="[]">
        <component :is="Component" />
      </KeepAlive>
    </transition>
  </RouterView>
</template>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.slide-left-enter-active, .slide-left-leave-active {
  transition: transform 1s;
}

.slide-left-enter, .slide-left-leave-to {
  transform: translateX(100%);
}

.slide-right-enter, .slide-right-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter-active, .slide-right-leave-active {
  transition: transform 1s;
}
</style>
